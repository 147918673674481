.projects { 
  text-align: left;
  flex: 2;
  max-height: 100%;
  overflow-y: auto;
}

.project-wrapper h5 {
  margin-bottom: 0;
}

.project-wrapper h5 > span {
  margin-right: .5rem;
}

.project-wrapper > span {
  font-size: 0.8em;
}

.project-wrapper > p {
  text-align: justify;
}

@media (min-width: 320px) and (max-width: 767px) {
  .projects {
    overflow-y: initial;
    max-height: initial;
  }
}