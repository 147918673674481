.landing {
  flex: 2;
  display: flex;
  flex-direction: column;
  height: calc(100% - 76px);
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  place-content: stretch space-between;
  width: 100vw;
  max-width: 980px;
  padding: 20px 40px;
  box-sizing: border-box;
  overflow: hidden;
}


@media (min-width: 320px) and (max-width: 767px) {
  .content-wrapper {
    flex-direction: column;
    place-content: start stretch;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px 20px;
  }
}