.footer-wrapper {
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  line-height: 21px;
  padding: 15px 0;
  place-content: center center;
  align-items: center;
  border-top: 1px solid;
  border-color: var(--border-color);
  width: 100vw;
  max-width: 900px;
  margin: 0 auto;
  font-size: 0.8em;
  height: 45px;
}

.footer-wrapper .social-wrapper {
  display: flex;
}

.footer-wrapper > p ,
.footer-wrapper .social-wrapper > a {
  margin-right: 10px;
  display: flex;
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer-wrapper {
    box-sizing: border-box;
  }
  .footer-wrapper .social-wrapper > a {
    height: 48px;
    width: 48px;
    align-items: center;
    place-content: center;
  }

  .footer-wrapper .social-wrapper > a svg{
    height: 24px!important;
    width: 24px!important;
  }
}