.skills {
  max-width: 40%;
  margin-right: 4em;
  text-align: left;
}

.skills-info {
  cursor: pointer;
}

.skills-wrapper > div {
  display: flex;
  place-content: center start;
  padding: 5px;
  align-items: center;
}

.skills-wrapper > div > span {
  margin: 0 5px;
  text-align: left;
  user-select: none;
  font-size: 0.9em;
}

.skills-wrapper > div > svg {
  flex: 0 0 auto;
}

@media (min-width: 320px) and (max-width: 767px) {
  .skills {
    max-width: initial;
    margin-right: 0;
  }

  .skills-wrapper {
    display: flex;  
    flex-wrap: wrap;
  }

  .skills-info {
    visibility: hidden;
  }
}